.verify img {
  max-height: 204px;
  max-width: 100%;
  object-fit: contain;
}
.verify {
  text-align: center;
}
.verify header {
  margin-top: 30px;
}
.verify .heading {
  text-transform: none;
  color: #2b3238;
}
.verify .header::after {
  display: none;
}
.verify .subheading {
  color: #80959d;
  font-size: 16px;
  letter-spacing: -0.2px;
}

.verify h2:after {
  content: "";
  display: block;
  width: 2.8125rem;
  height: 2px;
  margin: 1.25rem auto 0;
  background-color: #20c05c;
  box-shadow: 0 4px 4px 0 rgba(49, 177, 97, 0.28);
}
.account-created-wrp {
  max-width: 24.5rem;
  margin: auto;
}
.account-created-wrp .password-sent-mobile {
  background-color: #20c05c4d;
  font-size: 0.75rem;
  padding: 0.6875rem 1rem;
  border-radius: 4px;
  margin-bottom: 1.25rem;
  text-align: center;
}
.verify .button {
  height: 44px;
  text-decoration: none;
}
.verify .span {
  margin-bottom: 10px;
  color: #2b3238;
}
.verify .verify-error-text {
  color: #ea353a;
}

.verify .verify-success-text {
  color: #20c05c;
}

.verify-email-resend-wrp {
  font-size: 0.875rem;
  margin-top: 0.625rem;
}
@media (max-width: 600px) {
  .account-created-wrp {
    max-width: 100%;
    padding: 1.25rem;
  }
}
