:root {
  --border-color: #dadee0;
  --primary-text-color: #2b3238;
  --secondary-text-color: #80959d;
  --primary-color: #20c05c;
  --secondary-action-color: #f3f5f6;
  --separator-color: #eaefef;
  --bg-color: #fbfcfc;
  --disabled-color: #f4f7f9;
}
html {
  font-size: 16px;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #2b3238;
  margin: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: inherit;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

:focus {
  outline: none;
}

/* Page Container */
#app .app-main-wrp {
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
#app .verify-warning {
  margin-top: 2rem;
}
#app main {
  min-width: 320px;
  box-shadow: 2px 15px 9px 0 rgba(0, 0, 0, 0.15);
  padding: 30px 40px;
  z-index: 1;
}
#app main > div {
  position: relative;
}

#app main {
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  #app main {
    flex: 1 1;
  }
}

.fullWidthPage #main {
  background: url("./bg-footer.svg") repeat-x -42px bottom;
  min-height: calc(100% - 50px);
  background-position: bottom;
  padding: 15px 25px;
}
h1 {
  margin: 0 0 38px;
  font-size: 1.85rem;
  font-weight: bold;
  line-height: 0.93;
  letter-spacing: -0.7px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h1.title {
  margin-bottom: 40px;
  line-height: 1;
}

strong {
  font-weight: bold;
}

a {
  color: #20c05c;
  cursor: pointer;
  text-decoration: none;
}

img:not([src]) {
  display: none;
}

.flex {
  display: flex;
}

.flex-around {
  display: flex;
  justify-content: space-between;
  flex: 1; /* This is given so that elements with this class can be nested */
}

.flex-vertical {
  flex-direction: column;
}

.flex-item {
  flex: 1;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.plus-icon {
  height: 10px;
  width: 10px;
  background-image: url("./plus.svg");
  display: inline-block;
  margin-right: 8px;
}

small {
  font-size: 0.75rem;
}

.text-muted {
  color: #80959d;
}

.text-nowrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.red {
  color: #eb8181;
}

.green {
  color: #20c05c;
}

.zero-font {
  font-size: 0;
}

.grid {
  display: grid;
}

.bold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  /* Media Object */
  .media {
    display: flex;
  }
  .media-body {
    flex: 1;
  }
}
@media screen and (max-width: 840px) {
  h1.title {
    font-size: 1.5rem;
    margin-bottom: 30px;
    line-height: 26px;
  }
}

@media screen and (max-width: 480px) {
  #app main {
    padding: 1rem;
  }
  .xs-hidden {
    display: none;
  }
  h1.title {
    font-size: 1.125rem;
    line-height: 26px;
  }
  h1 {
    font-size: 1.125rem;
    line-height: 26px;
  }
  #app .verify-warning {
    margin-top: 6rem;
  }
}
