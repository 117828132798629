.product-variant-support .variant-form .field.property-toggle {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.product-variant-support .variant-form .property-item {
  display: flex;
  column-gap: 1rem;
}

.product-variant-support .variant-form .field {
  width: 25rem;
}

.product-variant-support .variant-form .add-button {
  min-width: 110px;
  height: 40px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 1px solid transparent;
  border-radius: 2px;
  letter-spacing: -0.3px;
  cursor: pointer;
  text-decoration: none;
  line-height: 40px;
  display: inline-block;
  text-align: center;
}

.product-variant-support .variant-form .delete-button {
  background-image: url(./delete-icon.svg);
  height: 40px;
  background-position: center;
  margin-top: 30px;
  margin-left: 5px;
  padding: 10px;
}
